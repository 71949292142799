<template>
    <div class="person-card">
        <div class="info" :class="{ 'info-active': active }">
            <HeadPortrait :imgUrl="avatarAssistant"></HeadPortrait>
            <div class="info-detail">
                <div class="name">{{ personInfo.name }}</div>
                <div class="detail">{{ personInfo.message }}</div>
            </div>

        </div>
    </div>
</template>

<script>
import HeadPortrait from './HeadPortrait.vue'

export default {
    props: {
        personInfo: {
            default: {},
        },
        active: {
            default: false,
        },
    },
    components: {
        HeadPortrait,
    },
    data() {
        return {
            avatarAssistant: require('@/assets/img/head_portrait0.png'),
        }
    },
    methods: {},
}
</script>

<style lang="less" scoped>
.person-card {
    margin: 20px 10px;

    .info {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        border-radius: 10px;
        padding: 10px 16px;
        height: 70px;
        background-color: #323644;
        max-width: 240px;

        &:hover {
            transition: 0.3s;
            background-color: #1d90f5 !important;
            box-shadow: 0 0 10px 0 #0088FF !important;

            .info-detail {
                .detail {
                    color: #ffffff !important;
                }
            }
        }

        .info-detail {
            flex: 1;
            margin-left: 10px;

            .name {
                color: #ffffff;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-bottom: 5px;
            }

            .detail {
                color: #5c6675;
                max-width: 120px;
                font-size: 12px;

                /*单行*/
                white-space: nowrap; //禁止文本换行
                overflow: hidden; //文本溢出的部分隐藏
                text-overflow: ellipsis; //文本溢出的部分用省略号表示
            }
        }
    }
}

.info-active {
    transition: 0.3s;
    background-color: #1d90f5 !important;
    box-shadow: 3px 2px 10px 0 #0088FFFF !important;

    .info-detail {
        .detail {
            color: #ffffff !important;
        }
    }
}
</style>