const storage = {
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    },
    get(key) {
        let development = process.env.NODE_ENV === 'development'
        //console.log(process.env.NODE_ENV)
        if (development && key.indexOf('key-cache-data-') > 0) {
            return null
        }
        let json = localStorage.getItem(key) || ''
        if (json && json.length) {
            return JSON.parse(json)
        } else {
            return null
        }
    },
    remove(key) {
        localStorage.removeItem(key)
    },
}
export default storage