<template>
    <div>
        <section class="side-qrcode">
            <div class="rollbar-top" title="返回顶部">
                <a class="top" href="#top"></a>
            </div>
            <div class="rollbar-qq" title="进群有神车，海量好物天天有">
                <div class="qq"></div>
            </div>
            <div class="rollbar-weixin" title="关注微信公众号">
                <div class="weixin"></div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    name: 'sideQrcode',
    props: {},
    data() {
        return {}
    },
    created() {
    },
    methods: {},
}
</script>

<style lang="less" scoped>
.side-qrcode {
    position: fixed;
    width: 50px;
    z-index: 999;
    right: 10px;
    bottom: 15px;

    .rollbar-qq, .rollbar-top, .rollbar-weixin {
        text-align: center;
        height: 50px;
        border-radius: 0;
        background-color: #666;
        color: #fff;
        opacity: 0.7;
        cursor: pointer;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
    }

    .rollbar-top:hover, .rollbar-qq:hover, .rollbar-weixin:hover {
        color: #fff;
        opacity: 1.0;
    }

    .rollbar-qq {
        background: url(./../assets/img/ic_site_qrcode.png) no-repeat scroll -104px 0;
    }

    .rollbar-top {
        background: url(./../assets/img/ic_site_qrcode.png) no-repeat scroll -208px 0;
    }

    .rollbar-weixin {
        background: url(./../assets/img/ic_site_qrcode.png) no-repeat scroll -156px 0;
    }

    .rollbar-qq:hover > .qq {
        display: block;
    }

    .rollbar-weixin:hover > .weixin {
        display: block;
    }

    .top {
        width: 50px;
        height: 50px;
        display: inline-block;
    }

    .qq {
        display: none;
        width: 164px;
        height: 206px;
        position: absolute;
        left: -164px;
        bottom: 0;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        background: url(./../assets/img/ic_site_qrcode.png) no-repeat scroll -164px -52px;
    }

    .weixin {
        display: none;
        width: 164px;
        height: 206px;
        position: absolute;
        left: -164px;
        bottom: 0;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        background: url(./../assets/img/ic_site_qrcode.png) no-repeat scroll 0 -52px;
    }
}
</style>
