<template>
    <div :class="{home : singlePage === false, iframe : singlePage}">
        <el-container style="height: 100%">
            <el-aside width="80px" v-if="singlePage === false">
                <Nav></Nav>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import Nav from '../../components/Nav.vue'

export default {
    name: 'ChatPc',
    components: {Nav},
    data() {
        return {
            singlePage: false,
        }
    },
    methods: {},
    watch: {
        '$route.query.iframe'(val) {
            let iframe = this.$route.query.iframe || ''
            this.singlePage = iframe === 'true'
        },
    },
    mounted() {
        let iframe = this.$route.query.iframe || ''
        this.singlePage = iframe === 'true'
    },
}
</script>

<style lang="less" scoped>

.home {
    height: 90%;
    margin: 0 10%;
    border-radius: 15px;
    background-color: #FAFAFA;
}

.iframe {
    height: 100%;
    border-radius: 15px;
    background-color: #FAFAFA;
}
</style>