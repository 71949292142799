import axios from 'axios'

import Vue from 'vue'
import { Message } from 'element-ui'

//全局参数，自定义参数可在发送请求时设置
axios.defaults.timeout = 60000
axios.defaults.withCredentials = true

let development = false
// let development = process.env.NODE_ENV === 'development'
let baseUrl = development ? 'http://localhost:9920/tbk/' : 'https://server.bwaq.cn/tbk/'

// 创建axios实例
const service = axios.create({
    baseURL: baseUrl,   // API 的 BASE_URL
    timeout: 60000 * 5, // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
    config => {
        config.headers['browserId'] = localStorage.getItem(C.Util.keyBrowserId()) || ''
        return config
    },
    error => {
        return Promise.reject(error)
    },
)

// response 拦截器
service.interceptors.response.use(
    response => {
        let data = response.data
        let responseCode = Number.parseInt(data.code)
        if (responseCode === 3010) {
            Message({type: 'warning', message: data.msg, duration: 5000})
            return Promise.reject(data)
        } else if (responseCode === 10) {
            return data.data
        } else {
            return Promise.reject(data)
        }
    },
    error => {
        return Promise.reject(error)
    },
)

Vue.prototype.$axios = service

export default {
    axios, baseUrl,
    getBaseUrl: () => {
        return baseUrl
    },
    get: (url, params, headers) => {
        console.log(params)
        return service.get(url, {
            params: params,
            headers: headers,
        })
    },
    post: (url, data) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return service.post(url, data, config)
    },
    put: (url, params) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return service.put(url, params, config)
    },
    delete: (url, params) => {
        return service.delete(url, params)
    },
    postForm: (url, params) => {
        let config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
        }
        let data = ''
        for (let param in params) {
            if (params.hasOwnProperty(param)) {
                data += encodeURIComponent(param) + '=' + (params[param] == null ? '' : encodeURIComponent(params[param])) + '&'
            }
        }
        console.log(data)
        return service.post(url, data, config)
    },
    postMultipart: (url, params) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8',
            },
        }
        return service.post(url, params, config)
    },
}
