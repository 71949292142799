<template>
    <div id="app-container">
        <div id="menu" :class="{on:isOpen}" @click="menuClient()" v-if="singlePage === false"><i></i></div>
        <div :class="{closed:!isOpen}" class="list" v-if="singlePage === false">
            <ul id="setting">
                <li :class="{selected:menuSelected === 'bookmark'}"
                    @click="menuSelect('bookmark')">
                    <a>书签</a>
                </li>
                <li v-if="false" :class="{selected:menuSelected === 'setting'}"
                    @click="menuSelect('setting')">
                    <a>设置</a>
                </li>
            </ul>
            <ul id="element">
            <span v-if="menuSelected === 'bookmark'">
                <Bookmarks></Bookmarks>
            </span>
                <span v-else-if="menuSelected === 'setting'">
                <Setting></Setting>
            </span>
            </ul>
        </div>
        <div id="content" ref="content" @click="menuClose()">
            <div id="top-menu-list" v-if="singlePage === false">
                <ul id="top-menu-ul">
                    <li v-for="(item, index) in linkList"
                        :key="index"
                        @click="goUrl(item.url)">
                        {{ item.title }}
                    </li>
                </ul>
            </div>
            <router-view></router-view>
        </div>
        <SideQrcode
            id="side-qrcode-container"
            v-if="visibleSideQrcode && singlePage === false">
        </SideQrcode>
    </div>
</template>
<script>

import packageJson from '../package.json'
import Bookmarks from '@/components/Bookmarks'
import Setting from '@/components/Setting'
import Fingerprint2 from 'fingerprintjs2'
import SideQrcode from '@/components/SideQrcode'

export default {
    name: 'Home',
    components: {
        Setting, Bookmarks, SideQrcode,
    },
    data() {
        return {
            singlePage: false,
            visibleSideQrcode: true,
            version: packageJson.version,
            isOpen: false,
            menuSelected: 'bookmark',
            background: '',
            linkList: [
                {
                    title: '首页',
                    url: '/',
                },
                {
                    title: '导航',
                    url: '/navigate',
                },
                {
                    title: '软件',
                    url: '/software',
                },
                {
                    title: 'ChatGPT',
                    url: '/chat',
                },
                {
                    title: '免费影视',
                    url: 'https://video.bwaq.cn',
                },
                {
                    title: '免费小说',
                    url: 'https://video.bwaq.cn/novel',
                },
                {
                    title: '电商优惠',
                    url: 'https://payback.bwaq.cn',
                },
            ],
        }
    },
    methods: {
        goUrl(path) {
            if (path.startsWith('http://') || path.startsWith('https://')) {
                window.open(path, '_blank')
            } else {
                this.$router.push({name: path}, () => {
                })
            }
        },
        getVisibleSideQrcode() {
            let path = this.$route.path
            console.log('path==>' + path)
            if (path === '/chatmobile') {
                return false
            } else {
                return true
            }
        },
        // 创建浏览器指纹
        createFingerprint() {
            // 浏览器指纹
            const fingerprint = Fingerprint2.get((components) => { // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
                const values = components.map(component => component.value) // 配置的值的数组
                const fingerprint = Fingerprint2.x64hash128(values.join(''), 31) // 生成浏览器指纹
                console.log(components)
                console.log(values)
                console.log('fingerprint' + fingerprint)
                localStorage.setItem(C.Util.keyBrowserId(), fingerprint) // 存储浏览器指纹，在项目中用于校验用户身份和埋点
            })
        },
        menuClient() {
            this.isOpen = !this.isOpen
        },
        menuClose() {
            this.isOpen = false
        },
        menuSelect(val) {
            this.menuSelected = val
        },
        setBackground(data) {
            this.background = data
        },
    },
    watch: {
        '$route.query.iframe'(val) {
            let iframe = this.$route.query.iframe || ''
            this.singlePage = iframe === 'true'
        },
        '$route.path'(val) {
            this.visibleSideQrcode = this.getVisibleSideQrcode()
        },
        background() {
            try {
                this.$refs.content.style.background = this.background
            } catch (error) {
                console.log(error)
            }
        },
    },
    mounted() {
        let iframe = this.$route.query.iframe || ''
        this.singlePage = iframe === 'true'

        this.visibleSideQrcode = this.getVisibleSideQrcode()
        // 您不应在页面加载时或加载后直接运行指纹。 而是使用setTimeout或requestIdleCallback将其延迟几毫秒，以确保指纹一致。
        let browserId = localStorage.getItem(C.Util.keyBrowserId()) || ''
        if (browserId.length === 0) {
            if (window.requestIdleCallback) {
                requestIdleCallback(() => {
                    this.createFingerprint()
                })
            } else {
                setTimeout(() => {
                    this.createFingerprint()
                }, 500)
            }
        }
        this.$refs.content.style.background = this.background
    },
}
</script>

<style lang='less'>

#app-container {
    width: 100%;
    height: 100%;
}

#content {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    -webkit-transition: all .5s ease-in;
    transition: all .5s ease-in;

    display: flex;
    flex-direction: column;
}

#top-menu-list {
    top: 15px;
    height: 60px;
    display: flex;
    align-items: center;

    #top-menu-ul {
        text-align: center;
        display: inline-block;
        height: 42px;
        margin: auto 0;

        li {
            float: left;
            width: 90px;
            cursor: pointer;
            list-style: none;
            text-align: center;
            margin: 4px 10px;
            white-space: nowrap;
            -webkit-transition: all .2s ease-in;
            transition: all .2s ease-in;
            padding: 0 8px;
            height: 32px;
            line-height: 32px;
            color: #666666;
            font-size: 16px;
            background-color: #FAFAFA;
        }
    }

}

/*侧边栏模块样式*/
#menu {
    width: 50px;
    height: 50px;
    transform: scale(0.8);
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
    z-index: 2001;

    i {
        position: fixed;
        right: 10px;
        margin: 24px auto;
        width: 30px;
        height: 2px;
        background: #444444;
        transition: 0.3s all ease;
    }

    i:before {
        content: '';
        width: 20px;
        height: 2px;
        top: -8px;
        background: #444444;
        position: absolute;
        right: 0;
        transition: 0.3s all ease;
    }

    i:after {
        content: '';
        width: 20px;
        height: 2px;
        bottom: -8px;
        background: #444444;
        position: absolute;
        left: 0;
        transition: 0.3s all ease;
    }
}

#menu.on {
    i {
        width: 25px;
        background: #444444;
    }

    i:before {
        top: -5px;
        transform: rotate(45deg);
        width: 14px;
        right: -1px;
        left: auto;
        background: #444444;
    }

    i:after {
        bottom: -5px;
        transform: rotate(-45deg);
        width: 14px;
        right: -1px;
        left: auto;
        background: #444444;
    }
}


.list {
    width: 376px;
    padding: 0 20px 0 0;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    z-index: 2000;
    background: #f7f7f7;
    transition: 0.3s all ease;

    ul {
        float: left;

        li {
            text-align: center;
            margin: 0 auto;
            float: left;
            -webkit-transition: all .2s ease-in;
            transition: all .2s ease-in
        }
    }

    #setting {
        width: 46px;
        padding: 20px 0 20px 0;

        li {
            width: 46px;
            margin: 6px 0;

            a {
                padding: 4px 6px;
                line-height: 24px;
                margin: 0 5px 0 5px;
            }
        }
    }

    #element {
        width: 294px;
        padding: 20px 0 0 5px;
        border-left: 1px solid #e9e9e9;

        li {
            width: 96px;
            padding: 9px 0;
            white-space: nowrap;

            a {
                vertical-align: middle;
                height: 32px;
                line-height: 32px;
                padding: 0 16px;
            }
        }

        li.element-title {
            width: 100%;
            padding: 9px 0;
            text-align: left;

            span {
                line-height: 24px;
                margin: 0;
                padding: 0 0 5px 0;
                border-bottom: 2px solid #838383;
                cursor: text;
                color: #999999;
                font-size: 14px;
            }
        }

        div {
            padding: 9px 0;
            line-height: 32px;
            float: left;
            color: #666666;
        }
    }
}

.list.closed {
    right: -376px;
}

/*侧边栏模块样式结束*/

@media screen and(max-width: 640px) {
    #menu, #top-menu-list, #side-qrcode-container {
        display: none;
    }
}

</style>