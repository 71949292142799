<template>
    <div :style="{ height: docHeight }" class="chat-home">
        <el-drawer
            :visible.sync="drawerVisible"
            size="75%"
            :withHeader="false"
            direction="ltr">
            <div class="chat-drawer">
                <div class="chat-title">
                    <span class="chat-title-text">途客</span>
                    <el-tag>欢迎使用</el-tag>
                </div>
                <el-popconfirm title="请确定清空聊天记录吗？"
                               @confirm="getChatMessageHistoryClear">
                    <el-button slot="reference"
                               type="danger"
                               size="mini"
                               class="chat-history-clear"
                               @click="visiblePopoverHistoryClear = true">
                        <i class="el-icon-delete"></i>&nbsp;清空聊天历史
                    </el-button>
                </el-popconfirm>
                <div class="chat-contacts">
                    <span class="chat-contacts-title">聊天列表</span>
                    <div class="chat-contacts-list">
                        <div
                            v-for="(contact, index) in contactList"
                            :key="index"
                            @click="onClickPerson(contact)"
                        >
                            <PersonCard
                                :active="contactSelect ? contactSelect.name === contact.name: false"
                                :personInfo="contact"
                            ></PersonCard>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
        <div ref="chatContent" class="chat-content"
             :style="{ minHeight: chatContentHeight }">

            <div v-for="(item, index) in chatList"
                 class="chat-wrapper"
                 :key="index">

                <div v-if="item.role === 'assistant'"
                     class="chat-to">
                    <div class="info-time" @click="onSelectChatRobot">
                        <img :src="avatarAssistant" alt=""/>
                        <span class="name">{{ item.name }}</span>
                        <span class="time">{{ item.time }}</span>
                    </div>
                    <template v-if="isSend && index === chatList.length - 1">
                        <!--打字机效果-->
                        <span class="flash-cursor"></span>
                    </template>
                    <template v-else>
                        <el-image
                            class="chat-img"
                            v-if="item.imageSrc && item.imageSrc.length"
                            @error="onErrorImage"
                            fit="contain"
                            :src="getImageUrl(item.imageSrc)"/>

                        <div class="chat-text" v-if="item.message && item.message.length">
                            <pre>{{ item.message }}</pre>
                        </div>
                    </template>
                </div>
                <div v-else class="chat-me">
                    <div class="info-time">
                        <span class="name">{{ item.time }}</span>
                        <span class="time">{{ item.name }}</span>
                        <img :src="avatarUser" alt=""/>
                    </div>
                    <el-image
                        class="chat-img"
                        v-if="item.imageSrc && item.imageSrc.length"
                        @error="onErrorImage"
                        fit="contain"
                        :src="getImageUrl(item.imageSrc)"/>

                    <div class="chat-text" v-if="item.message && item.message.length">
                        <pre>{{ item.message }}</pre>
                    </div>
                </div>
            </div>
        </div>
        <div class="chat-input-container">
            <div class="chat-input-type">
                <el-dropdown v-if="inputTypeSelect"
                             placement="top-start"
                             @command="onInputTypeChanged"
                             trigger="click">
                    <el-button type="text" @click="focusInputType = true">
                        输入模式&nbsp;{{ inputTypeSelect.name }}
                        <i :class="focusInputType ? 'el-icon-arrow-up el-icon--right' : 'el-icon-arrow-down el-icon--right'"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="(item,index) in inputType"
                            :command="item"
                            :divided="item.divider">
                            <el-button type="text"
                                       :style="{ color : item.type === inputTypeSelect.type ? '#409eff' : '#606266'}">
                                {{ item.name }}
                            </el-button>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <input
                class="chat-input-prompt"
                v-model="inputMessagePrompt"
                :placeholder="visibleInputMessagePrompPlaceholder"
                v-if="visibleInputMessagePromp"/>

            <div class="chat-input">
                <input v-model="inputMessage"
                       class="chat-input-view"
                       @keyup.enter="onSendAction"
                       :placeholder="visibleInputMessagePlaceholder"
                       v-if="visibleInputMessage"/>
                <el-button :disabled="isSend"
                           class="input-send"
                           @click="onSendAction">
                    <img alt="" src="@/assets/img/rocket.png"/>
                </el-button>
            </div>
        </div>

        <WxQrcodeDialog
            id="wx-qrcode-container"
            :visible.sync="visibleWxQrcode">
        </WxQrcodeDialog>
    </div>
</template>

<script>
import PersonCard from '@/components/PersonCard.vue'
import WxQrcodeDialog from '@/components/WxQrcodeDialog'
import Upload from '@/components/Upload'

import storage from '@/utils/storage'
import smoothscroll from 'smoothscroll-polyfill'

export default {
    name: 'ChatMobile',
    components: {
        PersonCard, WxQrcodeDialog, Upload
    },
    watch: {
        '$route.query.kw'(val) {
            this.inputMessage = val
        },
        inputMessage() {
            this.checkSendButton()
        },
        inputMessagePrompt() {
            this.checkSendButton()
        },
        contactSelect() {
            this.getChatMessage()
            this.getConversationList()
        },
        inputTypeSelect() {
            switch (this.inputTypeSelect.type) {
                case 'text-chat':
                case 'text-completion':
                case 'image-create':
                case 'image-edit':
                    this.visibleInputMessage = true
                    this.visibleInputMessagePromp = false
                    this.visibleInputMessagePlaceholder = ''
                    this.visibleInputMessagePrompPlaceholder = ''
                    break
                case 'text-edit':
                    this.visibleInputMessage = true
                    this.visibleInputMessagePromp = true
                    break
                case 'image-variation':
                    this.visibleInputMessage = false
                    this.visibleInputMessagePromp = false
                    break
                default:
                    break
            }

            switch (this.inputTypeSelect.type) {
                case 'text-chat':
                    this.visibleInputMessagePlaceholder = '请输入聊天文本'
                    this.visibleInputMessagePrompPlaceholder = ''
                    break
                case 'text-completion':
                    this.visibleInputMessagePlaceholder = '请输入文本'
                    this.visibleInputMessagePrompPlaceholder = ''
                    break
                case 'image-create':
                    this.visibleInputMessagePlaceholder = '请输入你要创建的图片的需求'
                    this.visibleInputMessagePrompPlaceholder = ''
                    break
                case 'image-edit':
                    this.visibleInputMessagePlaceholder = '请选择右边的素材并输入编辑需求'
                    this.visibleInputMessagePrompPlaceholder = ''
                    break
                case 'text-edit':
                    this.visibleInputMessagePlaceholder = '请输入编辑需求'
                    this.visibleInputMessagePrompPlaceholder = '请输入要编辑的文本内容'
                    break
                case 'image-variation':
                    this.visibleInputMessagePlaceholder = ''
                    this.visibleInputMessagePrompPlaceholder = ''
                    break
                default:
                    break
            }
            this.checkSendButton()
        },
    },
    data() {
        return {
            visibleWxQrcode: false,
            sendActionIntercepter: false,
            visibleInputMessage: true,
            visibleInputMessagePromp: false,

            visibleInputMessagePlaceholder: '',
            visibleInputMessagePrompPlaceholder: '',

            conversationKey: null,

            avatarUser: require('@/assets/img/head_portrait1.jpg'),
            avatarAssistant: require('@/assets/img/head_portrait0.png'),
            errorImage: require('@/assets/img/ic_image_onerror.jpeg'),

            docHeight: '',
            isSend: false,
            chatList: [],

            contactList: [],
            contactSelect: null,

            imageSrc: '',
            inputMessage: '',
            inputMessagePrompt: '',

            chatContentHeight: '',
            drawerVisible: false,

            focusInputType: false,
            inputTypeSelect: null,
            inputType: [
                {
                    type: 'text-chat',
                    name: '文本聊天',
                    divider: false,
                },
                {
                    type: 'text-completion',
                    name: '文本补全',
                    divider: false,
                },
                {
                    type: 'text-edit',
                    name: '文本编辑',
                    divider: false,
                },
                {
                    type: 'image-create',
                    name: '生成图片',
                    divider: true,
                },
            ],
        }
    },
    mounted() {
        if (C.Util.isPC()) {
            this.$router.replace({path: '/chat'})
        } else {
            this.inputMessage = this.$route.query.kw
            this.inputTypeSelect = this.inputType [0]
            smoothscroll.polyfill()
            C.Util.getClientHeight(this).then((height) => {
                this.docHeight = (height) + 'px'
                let inputHeight = document.getElementsByClassName('chat-input-container').offsetHeight
                this.chatContentHeight = (height - inputHeight) + 'px'
            })
            C.Api.getContact().then((response) => {
                this.contactList = response
                if (this.contactList && this.contactList.length) {
                    this.onClickPerson(this.contactList[0])
                }
            })
        }
    },
    methods: {
        onErrorImage(event) {
            let img = event.srcElement
            img.src = this.errorImage
            img.onerror = null //防止闪图
        },
        getImageUrl(imageUrl) {
            if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
                return imageUrl
            } else {
                return C.Request.getBaseUrl() + imageUrl
            }
        },
        //获取窗口高度并滚动至最底层
        // smooth(平滑滚动) instant(瞬间滚动)
        scrollBottom(smooth) {
            let that = this
            this.$nextTick(() => {
                let scrollView = that.$refs.chatContent
                if (scrollView) {
                    scrollView.scrollTo({
                        top: scrollView.scrollHeight,
                        behavior: smooth ? 'smooth' : 'instant',
                    })
                }
            })
        },
        onClickPerson(info) {
            this.drawerVisible = false
            if (this.contactSelect === info) {
                return
            }
            this.contactSelect = info
            this.getChatMessage()
        },
        onSelectChatRobot() {
            this.drawerVisible = true
        },
        //获取聊天记录
        getChatMessage() {
            C.Api.getChatMessageHistory(this.contactSelect.name).then((response) => {
                this.chatList = response
                this.contactSelect.message = this.chatList[this.chatList.length - 1].message
                this.scrollBottom(false)
            })
        },
        onSendFinally() {
            this.isSend = false
            storage.set('key-chat-message-history-' + this.contactSelect.name, this.chatList)
            this.getConversationList()
            this.scrollBottom(true)
        },
        //对话记录：有了聊天之后才有对话记录，所以首次初始化和发送消息时需要获取下
        getConversationList() {
            if (this.conversationKey === null || this.conversationKey.length === 0) {
                C.Api.getConversationList().then((response) => {
                    if (response && response.length) {
                        this.conversationKey = response[0]
                        console.log('conversationKey:' + this.conversationKey)
                    }
                })
            }
        },
        //将当前聊天的对象置顶
        onSortContact() {
            if (this.contactList.length < 1 || this.contactList === this.contactSelect) {
                return
            }
            let topContact
            for (let i = 0; i < this.contactList.length; i++) {
                let item = this.contactList[i]
                if (item.name === this.contactSelect.name) {
                    topContact = item
                    this.contactList.splice(i, 1)
                    break
                }
            }
            if (topContact) {
                this.contactList.unshift(topContact)
            }
        },
        onInputTypeChanged(item) {
            if (this.isSend) {
                return
            }
            console.log(JSON.stringify(item))
            this.focusInputType = false
            this.inputTypeSelect = item
        },
        //删除聊天记录
        getChatMessageHistoryClear() {
            this.drawerVisible = false
            let that = this
            C.Api.getChatMessageHistoryClear(that.contactSelect.name).then((response) => {
                that.$notify({
                    title: '操作成功',
                    message: '清理聊天记录成功',
                    type: 'success',
                    duration: 1000,
                })

                if (that.conversationKey) {
                    //删除对话记录：服务器上的
                    C.Api.delConversationList(that.conversationKey).then((response) => {
                        that.conversationKey = ''
                    })
                }

                that.chatList = response
                that.contactSelect.message = that.chatList[that.chatList.length - 1].message
                that.scrollBottom(false)
            })
        },
        onSendAction() {
            if (this.sendActionIntercepter) {
                this.$message({type: 'warning', message: '输入参数不能为空哦~'})
            } else {
                this.isSend = true
                let inputType = this.inputTypeSelect.type
                if (inputType === 'text-chat') {
                    this.sendText()
                } else if (inputType === 'text-completion') {
                    this.sendTextComplete()
                } else if (inputType === 'text-edit') {
                    this.sendTextEdit()
                } else if (inputType === 'image-create') {
                    this.sendImageCreate()
                } else if (inputType === 'image-edit') {
                    this.sendImageEdit()
                } else if (inputType === 'image-variation') {
                    this.sendImageVariation()
                }
                this.inputMessage = ''
                this.inputMessagePrompt = ''
            }
        },
        checkSendButton() {
            switch (this.inputTypeSelect.type) {
                case 'text-chat':
                case 'text-completion':
                case 'image-create':
                case 'image-edit':
                    this.sendActionIntercepter = C.Util.checkNull(this.inputMessage)
                    break
                case 'text-edit':
                    this.sendActionIntercepter = C.Util.checkNull(this.inputMessage) || C.Util.checkNull(this.inputMessagePrompt)
                    break
                case 'image-variation':
                    this.sendActionIntercepter = false
                    break
            }
        },
        //文本聊天
        sendText() {
            this.chatList.push(C.Util.getNewUserMessage(this.inputMessage))
            let newAssistantMessage = C.Util.getNewAssistantMessage()
            this.chatList.push(newAssistantMessage)
            this.scrollBottom(true)

            C.Api.getTextChat(this.inputMessage, this.contactSelect.name).then((response) => {
                newAssistantMessage.message = response
                this.onSortContact()
            }).catch((error) => {
                this.onChatException(error, newAssistantMessage)
            }).finally(() => {
                this.onSendFinally()
            })
        },
        //文本补全
        sendTextComplete() {
            this.chatList.push(C.Util.getNewUserMessage(this.inputMessage))
            let newAssistantMessage = C.Util.getNewAssistantMessage('', '')
            this.chatList.push(newAssistantMessage)
            this.scrollBottom(true)

            C.Api.getTextCompletion(this.inputMessage).then((response) => {
                newAssistantMessage.message = response
                this.onSortContact()
            }).catch((error) => {
                this.onChatException(error, newAssistantMessage)
            }).finally(() => {
                this.onSendFinally()
            })
        },
        //文本编辑
        sendTextEdit() {
            this.chatList.push(C.Util.getNewUserMessage(this.inputMessage))
            let newAssistantMessage = C.Util.getNewAssistantMessage('', '')
            this.chatList.push(newAssistantMessage)
            this.scrollBottom(true)

            /*1：文本内容 2：需求描述*/
            C.Api.getTextEdit(this.inputMessagePrompt, this.inputMessage).then((response) => {
                newAssistantMessage.message = response
                this.onSortContact()
            }).catch((error) => {
                this.onChatException(error, newAssistantMessage)
            }).finally(() => {
                this.onSendFinally()
            })
        },
        //生成图片
        sendImageCreate() {
            this.chatList.push(C.Util.getNewUserMessage(this.inputMessage))
            let newAssistantMessage = C.Util.getNewAssistantMessage('', '')
            this.chatList.push(newAssistantMessage)
            this.scrollBottom(true)

            C.Api.getImageCreate(this.inputMessage).then((response) => {
                newAssistantMessage.imageSrc = response
                this.onSortContact()
            }).catch((error) => {
                this.onChatException(error, newAssistantMessage)
            }).finally(() => {
                this.onSendFinally()
            })
        },
        //编辑图片
        sendImageEdit() {
            this.chatList.push(C.Util.getNewUserMessage(this.inputMessage, this.mediaSelect.imageUrl))
            let newAssistantMessage = C.Util.getNewAssistantMessage('', '')
            this.chatList.push(newAssistantMessage)
            this.scrollBottom(true)

            C.Api.getImageEdit(this.inputMessage, this.mediaSelect.imageId).then((response) => {
                newAssistantMessage.imageSrc = response
                this.onSortContact()
            }).catch((error) => {
                this.onChatException(error, newAssistantMessage)
            }).finally(() => {
                this.onSendFinally()
            })
        },
        //图片变换
        sendImageVariation() {
            this.chatList.push(C.Util.getNewUserMessage('图片变换', this.mediaSelect.imageUrl))
            let newAssistantMessage = C.Util.getNewAssistantMessage('', '')
            this.chatList.push(newAssistantMessage)
            this.scrollBottom(true)

            C.Api.getImageVariation(this.mediaSelect.imageId).then((response) => {
                newAssistantMessage.imageSrc = response
                this.onSortContact()
            }).catch((error) => {
                this.onChatException(error, newAssistantMessage)
            }).finally(() => {
                this.onSendFinally()
            })
        },
        onChatException(error, newMessage) {
            let reponseCode = Number.parseInt(error.code)
            if (reponseCode === 3010) {
                this.visibleWxQrcode = true
                newMessage.message = '请关注公众号获取使用额度'
            } else if (reponseCode !== 10) {
                newMessage.message = '账号额度已使用完，请使用其他平台吧！'
            } else {
                this.getChatMessage()
            }
        },
    },
}
</script>

<style lang="less" scoped>

@chat_input_size: 40px;
@chat_input_radius: 8px;

@color_333333: #333333;
@color_666666: #666666;
@color_ffffff: #ffffff;

@color_chat_me: #1D90F5;
@color_chat_me_hover: #1A81DB;
@color_chat_me_text: #ffffff;

@color_chat_to: #ffffff;
@color_chat_to_hover: #dddddd;
@color_chat_to_text: #333333;

@chat_window_color: #eeeeee;

@color_chat_name: #333333;
@color_chat_time: #656873;

@color_input_text: #333333;
@color_input_border: #0088FF;
@color_input_background: #ffffff;

@color_chat_send: #0088FF;
@color_chat_send_second: #1D90F5;

@color_chat_theme: #0088FF;

/deep/ .el-drawer {
    background-color: @color_ffffff !important;
}

.chat-home {
    overflow: hidden;
    background-color: @chat_window_color;

    display: flex;
    flex-direction: column;
    position: relative;


    .chat-content {
        flex: 1;
        width: 100%;
        overflow-y: scroll;
        padding: 14px;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            display: none;
        }

        .chat-wrapper {
            position: relative;
            word-break: break-all;
        }
    }
}

.chat-drawer {
    width: 280px;
    height: 100%;
    padding: 30px 0 30px 14px;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
    }

    .chat-title {
        margin-top: 10px;
        color: @color_333333;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .chat-title-text {
            color: @color_333333;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 10px
        }
    }

    .chat-history-clear {
        margin-top: 10px;
    }

    .chat-contacts {
        margin-top: 10px;

        .chat-contacts-title {
            color: @color_666666;
        }

        .chat-contacts-list {
            overflow: hidden;
            overflow-y: scroll;
            margin-left: -10px;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                display: none;
            }
        }
    }
}

.chat-to {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .chat-img {
        margin-top: 10px;
        max-width: 200px;
        border-radius: 10px;
    }

    .chat-text {
        max-width: 90%;
        padding: 4px 10px;
        border-radius: 5px 20px 20px 20px;
        background-color: @color_chat_to;
        color: @color_chat_to_text;

        &:hover {
            background-color: @color_chat_to_hover;
        }

        pre {
            white-space: break-spaces;
        }
    }

    .info-time {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        margin: 10px 0;

        img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }

        .name {
            color: @color_chat_name;
            margin: 0 5px;
        }

        .time {
            color: @color_chat_time;
            margin: 0 5px;
        }
    }

    .flash-cursor {
        width: 20px;
        height: 30px;
        display: inline-block;
        background: @color_333333;
        opacity: 1;
        animation: glow 800ms ease-out infinite alternate;
    }
}

.chat-me {
    width: 100%;
    float: right;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .chat-text {
        float: right;
        max-width: 90%;
        padding: 4px 10px;
        border-radius: 20px 5px 20px 20px;
        background-color: @color_chat_me;
        color: @color_chat_me_text;

        &:hover {
            background-color: @color_chat_me_hover;
        }

        pre {
            white-space: break-spaces;
        }
    }

    .chat-img {
        margin-top: 10px;
        max-width: 200px;
        border-radius: 10px;
    }

    .info-time {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        margin: 10px 0;

        img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }

        .name {
            color: @color_chat_name;
            margin: 0 5px;
        }

        .time {
            color: @color_chat_time;
            margin: 0 5px;
        }
    }
}

.chat-input-container {
    bottom: 0;
    padding: 10px 14px 10px;

    display: flex;
    flex-direction: column;
    background-color: @chat_window_color;

    .chat-input-type {

    }

    .chat-input-prompt {
        flex: 1;
        height: @chat_input_size;
        color: @color_input_text;
        border: 2px solid @color_input_border;
        background-color: @color_input_background;
        border-radius: @chat_input_radius;
        padding: 10px 14px;
        transition: 0.2s;
        font-size: 14px;
        margin-top: 6px;

        &:focus {
            outline: none;
        }
    }

    .chat-input {
        height: @chat_input_size;
        display: flex;
        flex-direction: row;
        margin-top: 6px;

        .chat-input-view {
            flex: 1;
            height: 100%;
            color: @color_input_text;
            border: 2px solid @color_input_border;
            background-color: @color_input_background;
            border-radius: @chat_input_radius;
            padding: 10px 14px;
            transition: 0.2s;
            font-size: 14px;

            &:focus {
                outline: none;
            }
        }

        .input-send {
            width: @chat_input_size;
            height: @chat_input_size;
            border-radius: @chat_input_radius;
            position: relative;
            cursor: pointer;
            border: 0;
            background-color: @color_chat_send;
            transition: 0.3s;
            box-shadow: 0 0 5px 0 @color_chat_send_second;
            margin-left: 10px;

            &:hover {
                box-shadow: 0 0 10px 0 @color_chat_send_second;
            }

            img {
                width: 24px;
                height: 24px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

    }
}

@keyframes glow {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0.5;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}
</style>
