<template>
    <div>
        <li class="element-title"><span>主页背景</span></li>
        <li><a>预设背景</a></li>
        <li><a>自定义</a></li>
    </div>
</template>

<script>
import storage from '@/utils/storage'

export default {
    name: 'Setting',
    data() {
        return {
            data: '',
            setting: '',
        }
    },
    methods: {
    },
}
</script>

<style lang="less" scoped>

</style>