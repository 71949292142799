import request from './request'
import storage from '@/utils/storage'

let axios = request.axios
let baseUrl = request.baseUrl

export default {
    // 获取联系人
    getContact() {
        return new Promise(function (resolve, reject) {
            let response = [
                {
                    name: 'ChatGPT',
                    role: 'assistant',
                    message: '',
                    description: ' ChatGPT是一个在线聊天机器人，它可以与用户进行自然语言交互，并回答用户的问题、提供信息和建议等，ChatGPT基于人工智能技术，使用深度学习算法训练而成，可以不断学习和改进自己的回答能力。',
                },
            ]
            resolve(response)
        })
    },

    // 获取聊天记录
    getChatMessageHistory(name) {
        return new Promise(function (resolve, reject) {
            let response = storage.get('key-chat-message-history-' + name)
            if (response === null || response.length === 0) {
                response = [
                    {
                        name: 'ChatGPT',
                        role: 'assistant',
                        time: new Date().toLocaleTimeString(),
                        message: ' ChatGPT为您服务',
                        imageSrc: '',
                    },
                ]
            }
            resolve(response)
        })
    },

    // 获取聊天记录
    getChatMessageHistoryClear(name) {
        return new Promise(function (resolve, reject) {
            storage.remove('key-chat-message-history-' + name)
            let response = [
                {
                    name: 'ChatGPT',
                    role: 'assistant',
                    time: new Date().toLocaleTimeString(),
                    message: ' ChatGPT为您服务',
                    imageSrc: '',
                },
            ]
            resolve(response)
        })
    },
    //对话记录
    getConversationList() {
        let browserId = localStorage.getItem(C.Util.keyBrowserId()) || ''
        return C.Request.get('/api/chatGPT/conversation/list', {
            browserId: browserId,
        })
    },
    //对话记录-删除
    delConversationList(conversationKey) {
        let browserId = localStorage.getItem(C.Util.keyBrowserId()) || ''
        return C.Request.get('/api/chatGPT/conversation/remove', {
            redisKey: conversationKey,
            browserId: browserId,
        })
    },
    //文本聊天
    getTextChat(inputMessage, contactName) {
        let browserId = localStorage.getItem(C.Util.keyBrowserId()) || ''
        return C.Request.get('api/chatGPT/text/chat', {
            content: inputMessage,
            browserId: browserId,
            conversation: contactName,
        })
    },
    //文本补全
    getTextCompletion(inputMessage) {
        let browserId = localStorage.getItem(C.Util.keyBrowserId()) || ''
        return C.Request.get('api/chatGPT/text/completion', {
            prompt: inputMessage,
            browserId: browserId,
        })
    },
    //文本编辑
    getTextEdit(input, instruction) {
        let browserId = localStorage.getItem(C.Util.keyBrowserId()) || ''
        return C.Request.get('api/chatGPT/text/edit', {
            input: input,//"我爱你麻麻"
            instruction: instruction,//"帮我修改错别字")
            model: 'text',
            browserId: browserId,
        })
    },
    //生成图片
    getImageCreate(prompt) {
        let browserId = localStorage.getItem(C.Util.keyBrowserId()) || ''
        return C.Request.get('api/chatGPT/image/create', {
            prompt: prompt,
            browserId: browserId,
        })
    },

    //编辑图片
    getImageEdit(prompt, imageId) {
        let browserId = localStorage.getItem(C.Util.keyBrowserId()) || ''
        return C.Request.get('api/chatGPT/image/edit', {
            prompt: prompt,
            imageId: imageId,
            browserId: browserId,
        })
    },
    //图片变换
    getImageVariation(imageId) {
        let browserId = localStorage.getItem(C.Util.keyBrowserId()) || ''
        return C.Request.get('api/chatGPT/image/variation', {
            imageId: imageId,
            browserId: browserId,
        })
    },

    // 获取素材
    getImageList() {
        let browserId = localStorage.getItem(C.Util.keyBrowserId()) || ''
        return C.Request.get('api/chatGPT/imageId/list', {
            browserId: browserId,
        })
    },

    // 删除素材
    imageDelete(imageId) {
        let browserId = localStorage.getItem(C.Util.keyBrowserId()) || ''
        return C.Request.get('api/chatGPT/imageId/delete', {
            imageId: imageId,
            browserId: browserId,
        })
    },

    // 书签类别
    getBookType(category) {
        let navigatorUrl = '/api/bookType/getListBySort?category=' + category
        return C.Request.get(navigatorUrl)
    },

    // 书签列表
    getBookMark(idListStr) {
        let bookMarkUrl = `api/bookMark/getListBySort?typeIds=${idListStr}`
        return C.Request.get(bookMarkUrl)
    },
}