<template>
    <div>
        <span v-if="bookMark">
            <span v-for="(item, index) in bookMark"
                  v-bind:key="index">
                <li class="element-title">
                    <span>{{ item.typeName }}</span>
                </li>
                <li v-for="(item, index) in item.data"
                    v-bind:key="index">
                    <a :href="item.clickUrl" rel="nofollow" target="_blank">
                        {{ item.remarks }}
                    </a>
                </li>
            </span>
        </span>
        <span v-else>努力加载中...</span>
    </div>
</template>

<script>
import storage from '@/utils/storage'

export default {
    name: 'Bookmarks',
    data() {
        return {
            storageKey: 'key-cache-data-bookmark-v01',
            bookMark: [],
        }
    },
    methods: {
        getBookType() {
            let data = storage.get(this.storageKey) || []
            if (data && data.length) {
                this.bookMark = data
            } else {
                C.Api.getBookType(1).then((response) => {
                    let bookType = response
                    let idListStr = []
                    for (let i = 0; i < bookType.length; i++) {
                        idListStr.unshift(bookType[i].id)
                    }
                    idListStr = idListStr.join(',')
                    this.getBookMarkUrl(idListStr)
                })
            }

        },
        getBookMarkUrl(idListStr) {
            C.Api.getBookMark(idListStr).then((response) => {
                this.bookMark = response
                if (this.bookMark && this.bookMark.length) {
                    this.bookMarkSelected = this.bookMark[0] || []
                }
                storage.set(this.storageKey, this.bookMark)
            })
        },
    },
    created() {
        this.getBookType()
    },
}
</script>

<style scoped lang="less">


</style>