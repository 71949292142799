<template>
    <div class="nav">
        <div class="nav-menu-wrapper">
            <ul class="menu-list">
                <li
                    v-for="(item, index) in menuList"
                    :key="index"
                    v-if="index === 0"
                    :class="{ activeNav: index === current }"
                    @click="changeMenu(index)"
                >
                    <div class="block"></div>
                    <span :class="item" class="iconfont"></span>
                </li>
            </ul>
        </div>
        <div class="own-pic">
            <HeadPortrait :imgUrl="imgUrl"></HeadPortrait>
        </div>
    </div>
</template>

<script>
import HeadPortrait from './HeadPortrait.vue'

export default {
    components: {
        HeadPortrait,
    },
    data() {
        return {
            imgUrl: require('@/assets/img/head_portrait0.png'),
            menuList: [
                'icon-xinxi',
                'icon-shipin',
                'icon-shu',
                'icon-shandian',
                'icon-shezhi',
            ],
            current: 0,
        }
    },
    methods: {
        changeMenu(index) {
            switch (index) {
                case 0:
                    this.$router.push({name: 'chat'}, () => {
                    })
                    break
                case 1:
                    this.$message('该功能还没有开发哦，敬请期待一下吧~🥳')
                    break
                case 2:
                    this.$message('该功能还没有开发哦，敬请期待一下吧~🥳')
                    break
                case 3:
                    this.$message('该功能还没有开发哦，敬请期待一下吧~🥳')
                    break
                case 4:
                    this.$message('该功能还没有开发哦，敬请期待一下吧~🥳')
                    break
            }

            this.current = index
        },
    },
}
</script>

<style lang="less" scoped>
.nav {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 20px 0 0 20px;

    .nav-menu-wrapper {
        position: absolute;
        top: 10%;
        transform: translate(0, -50%);

        .menu-list {

            li {
                margin: 0 0 0 -10px;
                list-style: none;
                cursor: pointer;
                position: relative;

                &:hover {
                    span {
                        color: #1D90F5;
                    }

                    .block {
                        opacity: 1;
                    }
                }

                .block {
                    background-color: #1D90F5;
                    position: absolute;
                    left: -30px;
                    width: 6px;
                    height: 25px;
                    transition: 0.5s;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    opacity: 0;
                }
            }
        }
    }

    .own-pic {
        position: absolute;
        bottom: 20px;
        margin-left: 25px;
    }
}

.activeNav {
    span {
        color: #1D90F5;
    }

    .block {
        opacity: 1 !important;
    }
}
</style>