import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import scroll from 'vue-seamless-scroll'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router/index'

//公共样式
import './style/common.less'
import './utils/index'

import VueClipBoard from 'vue-clipboard2'

Vue.use(scroll)
Vue.use(ElementUI)
Vue.use(VueClipBoard)

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
