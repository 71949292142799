<template>
    <div>
        <div class="wx-qrcode" v-if="visibleTemp">
            <div class="weixin"></div>
            <span class="close" @click="visibleTemp = false">&nbsp;✕&nbsp;</span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WxQrcodeDialog',
    props: {
        visible: {
            type: [Boolean],
            default: false,
        },
    },
    data(){
        return{
            visibleTemp:this.visible
        }
    },
    watch:{
        visible(){
            this.visibleTemp = this.visible
        }
    },
    created() {
        let that = this
        //监听键盘按钮
        document.onkeydown = function (event) {
            let e = event || window.event
            let keyCode = e.keyCode || e.which
            //console.log(keyCode)
            if (keyCode === 27) {
                that.visibleTemp = false
                if (e && e.preventDefault) {
                    e.preventDefault()
                } else {
                    window.event.returnValue = false
                }
            }
        }
    },
    methods: {},
}
</script>

<style lang="less" scoped>
@closeSize: 30px;
@borderRadius: 6px;

.wx-qrcode {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: #FFFFFF;

    width: 164px;
    height: 206px;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: @borderRadius;
    box-shadow: 1px 1px 6px 0 #CCCCCC;

    .close {
        right: 0;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        position: absolute;

        width: @closeSize;
        height: @closeSize;
        line-height: @closeSize;
        font-size: 14px;
        color: #666666;
    }

    .weixin {
        position: absolute;

        border-radius: @borderRadius;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        background: url(./../assets/img/ic_site_qrcode.png) no-repeat scroll 0 -52px;
    }
}

</style>
