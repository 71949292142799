const ua = navigator.userAgent.toUpperCase()

export default {

    ios() {
        return ua.indexOf('IPHONE OS') !== -1 || ua.indexOf('IPAD') !== -1
    },
    android() {
        return ua.indexOf('ANDROID') !== -1
    },

    qq() {
        return ua.indexOf('QQ') !== -1
    },
    wechat() {
        return ua.indexOf('MICROMESSENGER') !== -1
    },

    isPC() {
        let mobileOrPad = ['ANDROID', 'IPHONE', 'SYMBIANOS', 'WINDOWS PHONE', 'IPAD', 'IPOD']
        for (let i = 0; i < mobileOrPad.length; i++) {
            if (ua.indexOf(mobileOrPad[i]) > 0) {
                return false
            }
        }
        return true
    },
    isMobile() {
        return !this.isPC()
    },
    checkNull(input) {
        if (typeof input === 'undefined') {
            return true
        } else {
            return input === null || input.length === 0
        }
    },
    checkNotNull(input) {
        return !this.checkNull(input)
    },

    getClientHeight(that) {
        return new Promise((resolve, reject) => {
            that.$nextTick(() => {
                if (this.isPC()) {
                    resolve(window.innerHeight)
                } else {
                    resolve(document.documentElement.clientHeight)
                }
            })
        })
    },

    keyBrowserId() {
        return 'key-browser-id'
    },

    getNewUserMessage(message, messagePrompt, imageSrc) {
        return {
            name: '卧龙',
            time: new Date().toLocaleTimeString(),
            message: message || '',
            messagePrompt: messagePrompt || '',
            imageSrc: imageSrc || '',
        }
    },

    getNewAssistantMessage(responseMessage, responseImageSrc) {
        return {
            name: 'ChatGPT',
            role: 'assistant',
            time: new Date().toLocaleTimeString(),
            message: responseMessage || '',
            imageSrc: responseImageSrc || '',
        }
    },
}
