<template>
    <div class="chat-home">
        <div class="chat-left" v-if="singlePage === false">
            <div class="title">
                <h1 style="margin-bottom: 10px">途客</h1>
                <el-tag type="success">欢迎使用</el-tag>
                <br/>
            </div>
            <div class="chat-contacts">
                <span class="chat-contacts-title">聊天列表</span>
                <div class="chat-contacts-list">
                    <div
                        v-for="(contact, index) in contactList"
                        :key="index"
                        @click="onClickPerson(contact)"
                    >
                        <PersonCard
                            :active="contactSelect ? contactSelect.name === contact.name: false"
                            :personInfo="contact"
                        ></PersonCard>
                    </div>
                </div>
            </div>
        </div>
        <div class="chat-right">
            <div v-if="contactSelect" class="chat-right-view">
                <div class="chat-info" v-if="singlePage === false">
                    <div class="chat-info-pic">
                        <HeadPortrait :imgUrl="avatarAssistant"></HeadPortrait>
                    </div>
                    <div class="chat-info-detail">
                        <div class="chat-info-detail-name">{{ contactSelect.name }}</div>
                        <div class="chat-info-detail-desc">{{ contactSelect.description }}</div>
                    </div>
                </div>
                <div :style="{ height: docHeight }" class="chat-window-container">
                    <div class="chat-window">
                        <div ref="chatContent" class="chat-content">
                            <div v-for="(item, index) in chatList"
                                 :key="index" class="chat-wrapper">
                                <div v-if="item.role === 'assistant'"
                                     class="chat-to">
                                    <div class="info-time">
                                        <img :src="avatarAssistant" alt=""/>
                                        <span class="name">{{ item.name }}</span>
                                        <span class="time">{{ item.time }}</span>
                                    </div>
                                    <template v-if="isSend && index === chatList.length - 1">
                                        <!--打字机效果-->
                                        <span class="flash-cursor"></span>
                                    </template>
                                    <template v-else>
                                        <el-image
                                            class="chat-img"
                                            v-if="item.imageSrc && item.imageSrc.length"
                                            @error="onErrorImage"
                                            fit="contain"
                                            :src="getImageUrl(item.imageSrc)"/>

                                        <div class="chat-text"
                                             v-if="item.message && item.message.length">
                                            <pre>{{ item.message }}</pre>
                                        </div>
                                    </template>
                                </div>
                                <div v-else class="chat-me">
                                    <div class="info-time">
                                        <span class="name">{{ item.time }}</span>
                                        <span class="time">{{ item.name }}</span>
                                        <img :src="avatarUser" alt=""/>
                                    </div>

                                    <el-image
                                        class="chat-img"
                                        v-if="item.imageSrc && item.imageSrc.length"
                                        @error="onErrorImage"
                                        fit="contain"
                                        :src="getImageUrl(item.imageSrc)"/>

                                    <div class="chat-text"
                                         v-if="item.message && item.message.length">
                                        <pre>{{ item.message }}</pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="chat-input-container">
                            <div class="chat-input-type">
                                <el-dropdown
                                    v-if="inputTypeSelect"
                                    placement="top-start"
                                    @command="onInputTypeChanged"
                                    trigger="hover">
                                    <el-button
                                        type="text"
                                        @mouseover.native="focusInputType = true"
                                        @mouseout.native="focusInputType = false">
                                        输入模式&nbsp;{{ inputTypeSelect.name }}
                                        <i :class="focusInputType ? 'el-icon-arrow-up el-icon--right' : 'el-icon-arrow-down el-icon--right'"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                            v-for="(item,index) in inputType"
                                            :command="item"
                                            :disabled="item.disable"
                                            :divided="item.divider">
                                            <el-button
                                                type="text"
                                                :style="{ color : getInputTypeSelectColor(item) }">
                                                {{ item.name }}
                                                <i v-if="item.disable" class="el-icon-lock"></i>
                                                <i v-else-if="item.isPro" class="el-icon-key"></i>

                                            </el-button>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>

                                <el-popconfirm
                                    title="请确定清空聊天记录吗？"
                                    @confirm="getChatMessageHistoryClear">
                                         <span slot="reference"
                                               style="text-align: center;line-height: 40px;cursor: pointer"
                                               class="el-iconfont el-icon-delete-solid"
                                               @click="visiblePopoverHistoryClear = true">
                                        </span>
                                </el-popconfirm>
                            </div>
                            <input
                                class="chat-input-prompt"
                                v-model="inputMessagePrompt"
                                :placeholder="visibleInputMessagePrompPlaceholder"
                                v-if="visibleInputMessagePromp"/>

                            <div class="chat-input">

                                <input v-model="inputMessage"
                                       class="chat-input-view"
                                       :placeholder="visibleInputMessagePlaceholder"
                                       @keyup.enter="onSendAction"
                                       v-if="visibleInputMessage"/>

                                <div class="chat-input-space" v-if="visibleInputMessage"></div>

                                <div
                                    class="input-send"
                                    @click="onSendAction">
                                    <span
                                        v-if="inputTypeSelect && inputTypeSelect.type === 'image-variation'">图片变换</span>
                                    <img alt="" src="@/assets/img/rocket.png"/>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="chat-media" v-if="proEnable">
                        <span class="chat-media-title">
                            <el-button type="text" size="middle" style="cursor: default">
                                素材列表
                            </el-button>
                            <Upload
                                :limit="1"
                                accept=".jpeg,.png,.jpg"
                                @onSuccess="imageUpload"
                            >
                                <el-button type="text" size="middle">
                                    添加<i style="margin-left: 1px" class="el-icon-plus avatar-uploader-icon"></i>
                                </el-button>
                            </Upload>
                        </span>

                        <div class="chat-media-list">
                            <div class="chat-media-item"
                                 v-for="(media, index) in mediaList"
                                 :class="{select: mediaSelect && mediaSelect.imageId === media.imageId}"
                            >
                                <img
                                    class="chat-media-img"
                                    alt=""
                                    @error="onErrorImage"
                                    @click="onClickMedia(media)"
                                    :src="getImageUrl(media.imageUrl)"/>
                                <img
                                    class="chat-media-img-delete"
                                    alt=""
                                    @click="imageDelete(media)"
                                    src="@/assets/img/ic_close_grey_circle_half.png">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div v-else class="chat-right-empty-view">
                <span class="iconfont icon-snapchat"></span>
            </div>
        </div>

        <WxQrcodeDialog
            id="wx-qrcode-container"
            :visible.sync="visibleWxQrcode">
        </WxQrcodeDialog>
    </div>
</template>

<script>
import HeadPortrait from '@/components/HeadPortrait'
import PersonCard from '@/components/PersonCard.vue'
import storage from '@/utils/storage'
import WxQrcodeDialog from '@/components/WxQrcodeDialog'
import Upload from '@/components/Upload'

export default {
    name: 'Chat',
    components: {
        PersonCard, HeadPortrait, WxQrcodeDialog, Upload
    },
    watch: {
        '$route.query.iframe'(val) {
            let iframe = this.$route.query.iframe || ''
            this.singlePage = iframe === 'true'
            this.proEnable = this.singlePage === false
        },
        '$route.query.kw'(val) {
            this.inputMessage = val
        },
        mediaSelect() {
            this.checkSendButton()
        },
        inputMessage() {
            this.checkSendButton()
        },
        inputMessagePrompt() {
            this.checkSendButton()
        },
        contactSelect() {
            this.getImageList()
            this.getChatMessage()
            this.getConversationList()
        },
        inputTypeSelect() {
            switch (this.inputTypeSelect.type) {
                case 'text-chat':
                case 'text-completion':
                case 'image-create':
                case 'image-edit':
                    this.visibleInputMessage = true
                    this.visibleInputMessagePromp = false
                    break
                case 'text-edit':
                    this.visibleInputMessage = true
                    this.visibleInputMessagePromp = true
                    break
                case 'image-variation':
                    this.visibleInputMessage = false
                    this.visibleInputMessagePromp = false
                    break
                default:
                    break
            }
            switch (this.inputTypeSelect.type) {
                case 'text-chat':
                    this.visibleInputMessagePlaceholder = '请输入聊天文本'
                    this.visibleInputMessagePrompPlaceholder = ''
                    break
                case 'text-completion':
                    this.visibleInputMessagePlaceholder = '请输入文本'
                    this.visibleInputMessagePrompPlaceholder = ''
                    break
                case 'image-create':
                    this.visibleInputMessagePlaceholder = '请输入你要创建的图片的需求'
                    this.visibleInputMessagePrompPlaceholder = ''
                    break
                case 'image-edit':
                    this.visibleInputMessagePlaceholder = '请选择右边的素材并输入编辑需求'
                    this.visibleInputMessagePrompPlaceholder = ''
                    break
                case 'text-edit':
                    this.visibleInputMessagePlaceholder = '请输入编辑需求'
                    this.visibleInputMessagePrompPlaceholder = '请输入要编辑的文本内容'
                    break
                case 'image-variation':
                    this.visibleInputMessagePlaceholder = ''
                    this.visibleInputMessagePrompPlaceholder = ''
                    break
                default:
                    break
            }
            this.checkSendButton()
        },
    },
    data() {
        return {
            singlePage: false,
            visibleWxQrcode: false,

            sendActionIntercepter: false,
            visibleInputMessage: true,
            visibleInputMessagePromp: false,

            visibleInputMessagePlaceholder: '',
            visibleInputMessagePrompPlaceholder: '',

            proEnable: true,
            conversationKey: null,

            avatarUser: require('@/assets/img/head_portrait1.jpg'),
            avatarAssistant: require('@/assets/img/head_portrait0.png'),
            errorImage: require('@/assets/img/ic_image_onerror.png'),

            docHeight: '',
            isSend: false,
            chatList: [],

            contactList: [],
            contactSelect: null,

            imageSrc: '',
            inputMessage: '',
            inputMessagePrompt: '',

            mediaList: [],
            mediaSelect: null,

            focusInputType: false,
            inputTypeSelect: null,
            inputType: [
                {
                    type: 'text-chat',
                    name: '文本聊天',
                    divider: false,
                    disable: false,
                    isPro: false,
                },
                {
                    type: 'text-completion',
                    name: '文本补全',
                    divider: false,
                    disable: false,
                    isPro: false,
                },
                {
                    type: 'text-edit',
                    name: '文本编辑',
                    divider: false,
                    disable: false,
                    isPro: false,
                },
                {
                    type: 'image-create',
                    name: '生成图片',
                    divider: true,
                    disable: false,
                    isPro: false,
                },
                {
                    type: 'image-edit',
                    name: '编辑图片',
                    divider: false,
                    disable: true,
                    isPro: true,
                },
                {
                    type: 'image-variation',
                    name: '图片变换',
                    divider: false,
                    disable: true,
                    isPro: true,
                },
            ],
        }
    },
    mounted() {

        if (C.Util.isMobile()) {
            this.$router.replace({path: '/chatmobile'})
        } else {
            let iframe = this.$route.query.iframe || ''
            this.singlePage = iframe === 'true'
            this.proEnable = this.singlePage === false

            this.inputMessage = this.$route.query.kw

            this.inputType.forEach(value => {
                if (value.disable && this.proEnable) {
                    value.disable = false
                }
            })

            this.inputTypeSelect = this.inputType [0]

            C.Util.getClientHeight(this).then((height) => {
                this.docHeight = (height * 0.78) + 'px'
            })
            C.Api.getContact().then((response) => {
                this.contactList = response
                if (this.contactList && this.contactList.length) {
                    this.onClickPerson(this.contactList[0])
                }
            })
        }
    },
    methods: {
        onErrorImage(event) {
            let img = event.srcElement
            img.src = this.errorImage
            img.onerror = null //防止闪图
        },
        getImageUrl(imageUrl) {
            if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
                return imageUrl
            } else {
                return C.Request.getBaseUrl() + imageUrl
            }
        },
        //获取窗口高度并滚动至最底层
        // smooth(平滑滚动) instant(瞬间滚动)
        scrollBottom(smooth) {
            let that = this
            this.$nextTick(() => {
                let scrollView = that.$refs.chatContent
                if (scrollView) {
                    scrollView.scrollTo({
                        top: scrollView.scrollHeight,
                        behavior: smooth ? 'smooth' : 'instant',
                    })
                }
            })
        },
        getInputTypeSelectColor(item) {
            if (item.disable) {
                return '#AAAAAA'
            } else if (item.isPro) {
                return this.inputTypeSelect.type === item.type ? '#409eff' : '#E6A23C'
            } else {
                return this.inputTypeSelect.type === item.type ? '#409eff' : '#606266'
            }
        },
        onClickPerson(info) {
            if (this.contactSelect === info) {
                return
            }
            this.contactSelect = info
            this.getChatMessage()
        },
        onClickMedia(media) {
            if (this.mediaSelect === media) {
                return
            }
            this.mediaSelect = media
        },
        //获取聊天记录
        getChatMessage() {
            C.Api.getChatMessageHistory(this.contactSelect.name).then((response) => {
                this.chatList = response
                this.contactSelect.message = this.chatList[this.chatList.length - 1].message
                this.scrollBottom(false)
            })
        },
        onSendFinally() {
            this.isSend = false
            storage.set('key-chat-message-history-' + this.contactSelect.name, this.chatList)
            this.getConversationList()
            this.scrollBottom(true)
        },
        //对话记录：有了聊天之后才有对话记录，所以首次初始化和发送消息时需要获取下
        getConversationList() {
            if (this.conversationKey === null || this.conversationKey.length === 0) {
                C.Api.getConversationList().then((response) => {
                    if (response && response.length) {
                        this.conversationKey = response[0]
                        console.log('conversationKey:' + this.conversationKey)
                    }
                })
            }
        },
        //获取素材
        getImageList() {
            C.Api.getImageList().then((response) => {
                this.mediaList = response || []
            })
        },
        //删除素材
        imageDelete(media) {
            if (this.mediaSelect && this.mediaSelect.imageId === media.imageId) {
                this.mediaSelect = null
            }
            C.Api.imageDelete(media.imageId).then((response) => {
                this.getImageList()
            })
        },
        //上传素材
        imageUpload(response) {
            this.getImageList()
        },
        //将当前聊天的对象置顶
        onSortContact() {
            if (this.contactList.length < 1 || this.contactList === this.contactSelect) {
                return
            }
            let topContact
            for (let i = 0; i < this.contactList.length; i++) {
                let item = this.contactList[i]
                if (item.name === this.contactSelect.name) {
                    topContact = item
                    this.contactList.splice(i, 1)
                    break
                }
            }
            if (topContact) {
                this.contactList.unshift(topContact)
            }
        },
        onInputTypeChanged(item) {
            if (this.isSend) {
                return
            }
            console.log(JSON.stringify(item))
            this.focusInputType = false
            this.inputTypeSelect = item
        },
        //删除聊天记录
        getChatMessageHistoryClear() {
            let that = this
            C.Api.getChatMessageHistoryClear(that.contactSelect.name).then((response) => {
                that.$notify({
                    title: '操作成功',
                    message: '清理聊天记录成功',
                    type: 'success',
                    duration: 1000,
                })

                if (that.conversationKey) {
                    //删除对话记录：服务器上的
                    C.Api.delConversationList(that.conversationKey).then((response) => {
                        that.conversationKey = ''
                    })
                }
                that.chatList = response
                that.contactSelect.message = that.chatList[that.chatList.length - 1].message
                that.scrollBottom(false)
            })
        },
        onSendAction() {
            if (this.isSend) {
                return
            }
            if (this.sendActionIntercepter) {
                this.$message({type: 'warning', message: '输入参数不能为空哦~'})
            } else {
                this.isSend = true
                let inputType = this.inputTypeSelect.type
                if (inputType === 'text-chat') {
                    this.sendText()
                } else if (inputType === 'text-completion') {
                    this.sendTextComplete()
                } else if (inputType === 'text-edit') {
                    this.sendTextEdit()
                } else if (inputType === 'image-create') {
                    this.sendImageCreate()
                } else if (inputType === 'image-edit') {
                    this.sendImageEdit()
                } else if (inputType === 'image-variation') {
                    this.sendImageVariation()
                }
                this.inputMessage = ''
                this.inputMessagePrompt = ''
            }
        },
        checkSendButton() {
            switch (this.inputTypeSelect.type) {
                case 'image-edit':
                    this.sendActionIntercepter = C.Util.checkNull(this.inputMessage)
                        || this.mediaSelect === null
                    break
                case 'image-create':
                case 'text-chat':
                case 'text-completion':
                    this.sendActionIntercepter = C.Util.checkNull(this.inputMessage)
                    break
                case 'text-edit':
                    this.sendActionIntercepter = C.Util.checkNull(this.inputMessage)
                        || C.Util.checkNull(this.inputMessagePrompt)
                    break
                case 'image-variation':
                    this.sendActionIntercepter = this.mediaSelect === null
                    break
            }
        },
        //文本聊天
        sendText() {
            this.chatList.push(C.Util.getNewUserMessage(this.inputMessage))
            let newAssistantMessage = C.Util.getNewAssistantMessage()
            this.chatList.push(newAssistantMessage)
            this.scrollBottom(true)

            C.Api.getTextChat(this.inputMessage, this.contactSelect.name).then((response) => {
                newAssistantMessage.message = response
                this.onSortContact()
            }).catch((error) => {
                this.onChatException(error, newAssistantMessage)
            }).finally(() => {
                this.onSendFinally()
            })
        },
        //文本补全
        sendTextComplete() {
            this.chatList.push(C.Util.getNewUserMessage(this.inputMessage))
            let newAssistantMessage = C.Util.getNewAssistantMessage('', '')
            this.chatList.push(newAssistantMessage)
            this.scrollBottom(true)

            C.Api.getTextCompletion(this.inputMessage).then((response) => {
                newAssistantMessage.message = response
                this.onSortContact()
            }).catch((error) => {
                this.onChatException(error, newAssistantMessage)
            }).finally(() => {
                this.onSendFinally()
            })
        },
        //文本编辑
        sendTextEdit() {
            this.chatList.push(C.Util.getNewUserMessage(this.inputMessage))
            let newAssistantMessage = C.Util.getNewAssistantMessage('', '')
            this.chatList.push(newAssistantMessage)
            this.scrollBottom(true)

            /*1：文本内容 2：需求描述*/
            C.Api.getTextEdit(this.inputMessagePrompt, this.inputMessage).then((response) => {
                newAssistantMessage.message = response
                this.onSortContact()
            }).catch((error) => {
                this.onChatException(error, newAssistantMessage)
            }).finally(() => {
                this.onSendFinally()
            })
        },
        //生成图片
        sendImageCreate() {
            this.chatList.push(C.Util.getNewUserMessage(this.inputMessage))
            let newAssistantMessage = C.Util.getNewAssistantMessage('', '')
            this.chatList.push(newAssistantMessage)
            this.scrollBottom(true)

            C.Api.getImageCreate(this.inputMessage).then((response) => {
                newAssistantMessage.imageSrc = response
                this.onSortContact()
            }).catch((error) => {
                this.onChatException(error, newAssistantMessage)
            }).finally(() => {
                this.onSendFinally()
            })
        },
        //编辑图片
        sendImageEdit() {
            this.chatList.push(C.Util.getNewUserMessage(this.inputMessage, this.mediaSelect.imageUrl))
            let newAssistantMessage = C.Util.getNewAssistantMessage('', '')
            this.chatList.push(newAssistantMessage)
            this.scrollBottom(true)

            C.Api.getImageEdit(this.inputMessage, this.mediaSelect.imageId).then((response) => {
                newAssistantMessage.imageSrc = response
                this.onSortContact()
            }).catch((error) => {
                this.onChatException(error, newAssistantMessage)
            }).finally(() => {
                this.onSendFinally()
            })
        },
        //图片变换
        sendImageVariation() {
            this.chatList.push(C.Util.getNewUserMessage('图片变换', this.mediaSelect.imageUrl))
            let newAssistantMessage = C.Util.getNewAssistantMessage('', '')
            this.chatList.push(newAssistantMessage)
            this.scrollBottom(true)

            C.Api.getImageVariation(this.mediaSelect.imageId).then((response) => {
                newAssistantMessage.imageSrc = response
                this.onSortContact()
            }).catch((error) => {
                this.onChatException(error, newAssistantMessage)
            }).finally(() => {
                this.onSendFinally()
            })
        },
        onChatException(error, newMessage) {
            let reponseCode = Number.parseInt(error.code)
            if (reponseCode === 3010) {
                this.visibleWxQrcode = true
                newMessage.message = '请关注公众号获取使用额度'
            } else if (reponseCode !== 10) {
                newMessage.message = '账号额度已使用完，请使用其他平台吧！'
            } else {
                this.getChatMessage()
            }
        },
    },
}
</script>

<style lang="less" scoped>

@chat_input_size: 44px;
@chat_input_radius: 8px;

@chat_radius_media: 14px;
@chat_radius_window: 14px;
@chat_radius_media_image: 4px;

@color_333333: #333333;
@color_666666: #666666;
@color_999999: #999999;
@color_ffffff: #ffffff;
@color_cccccc: #cccccc;

@color_chat_me: #1D90F5;
@color_chat_me_hover: #1A81DB;
@color_chat_me_text: #ffffff;

@color_chat_to: #ffffff;
@color_chat_to_hover: #dddddd;
@color_chat_to_text: #333333;

@chat_window_color: #eeeeee;

@color_chat_name: #333333;
@color_chat_time: #656873;

@color_input_text: #333333;
@color_input_border: #0088FF;
@color_input_background: #ffffff;

@color_chat_send: #0088FF;
@color_chat_send_second: #1D90F5;

@color_chat_theme: #0088FF;

.chat-home {
    height: 100%;
    display: flex;
}

.chat-left {
    width: 280px;

    .title {
        color: @color_333333;
        padding-left: 10px;
    }

    .chat-contacts {
        margin-top: 40px;

        .chat-contacts-title {
            padding-left: 10px;
            color: @color_666666;
        }

        .chat-contacts-list {
            overflow: hidden;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                display: none;
            }
        }
    }
}

.chat-right {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: row;

    .chat-right-view {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .chat-right-empty-view {
        position: absolute;
        top: calc(50% - 150px);
        left: calc(50% - 50px);

        .icon-snapchat {
            width: 300px;
            height: 300px;
            font-size: 300px;
        }
    }
}

.chat-info {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .chat-info-pic {
    }

    .chat-info-detail {
        flex: 1;
        margin: 0 10px 0;
        display: flex;
        flex-direction: column;

        .chat-info-detail-name {
            font-size: 18px;
            font-weight: 600;
            color: @color_333333;
        }

        .chat-info-detail-desc {
            color: @color_666666;
            font-size: 12px;
            margin-top: 2px;
        }
    }
}

.chat-window-container {
    flex: 1;
    display: flex;
    flex-direction: row;

    .chat-window {
        width: 100%;
        background-color: @chat_window_color;
        border-radius: @chat_radius_window;
        display: flex;
        flex-direction: column;
        flex: 1;

        .chat-content {
            flex: 1;
            width: 100%;
            padding: 20px;
            overflow-y: scroll;
            border-top-left-radius: @chat_radius_window;
            border-top-right-radius: @chat_radius_window;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                display: none;
            }
        }

        .chat-input-container {
            display: flex;
            flex-direction: column;
            padding: 20px;
            background-color: @chat_window_color;
            border-bottom-left-radius: @chat_radius_window;
            border-bottom-right-radius: @chat_radius_window;

            .chat-input-type {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .chat-input-prompt {
                flex: 1;
                height: @chat_input_size;
                color: @color_input_text;
                border: 2px solid @color_input_border;
                background-color: @color_input_background;
                border-radius: @chat_input_radius;
                padding: 10px 14px;
                transition: 0.2s;
                font-size: 16px;
                font-weight: 100;
                margin-top: 6px;

                &:focus {
                    outline: none;
                }
            }

            .chat-input {
                width: 100%;
                height: @chat_input_size;
                display: flex;
                flex-direction: row;
                margin-top: 6px;

                .chat-input-view {
                    flex: 1;
                    height: 100%;
                    color: @color_input_text;
                    border: 2px solid @color_input_border;
                    background-color: @color_input_background;
                    border-radius: @chat_input_radius;
                    padding: 10px 14px;
                    transition: 0.2s;
                    font-size: 16px;
                    font-weight: 100;

                    &:focus {
                        outline: none;
                    }
                }

                .chat-input-space {
                    width: 20px;
                }

                .input-send {
                    height: @chat_input_size;
                    border-radius: @chat_input_radius;
                    position: relative;
                    cursor: pointer;
                    padding: 10px;
                    font-size: 15px;

                    border: 0;
                    background-color: @color_chat_send;
                    transition: 0.3s;
                    box-shadow: 0 0 5px 0 @color_chat_send_second;

                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        box-shadow: 0 0 10px 0 @color_chat_send_second;
                    }

                    span {
                        padding: 0 6px;
                        color: @color_ffffff;
                        line-height: 30px;

                        /*单行*/
                        white-space: nowrap; //禁止文本换行
                        overflow: hidden; //文本溢出的部分隐藏
                        text-overflow: ellipsis; //文本溢出的部分用省略号表示
                    }

                    img {
                        width: 26px;
                        height: 26px;
                    }
                }
            }
        }
    }

    .chat-media {
        width: 160px;
        height: 100%;
        margin-left: 20px;
        align-items: center;
        display: flex;
        flex-direction: column;
        align-self: end;

        .chat-media-title {
            width: 100%;
            padding: 0 @chat_radius_media;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: @chat_window_color;
            border-top-left-radius: @chat_radius_media;
            border-top-right-radius: @chat_radius_media;
        }

        .chat-media-list {
            width: 100%;
            height: 100%;
            padding: @chat_radius_media;
            padding-top: 0 !important;
            overflow: hidden;
            overflow-y: scroll;
            background-color: @chat_window_color;
            border-bottom-left-radius: @chat_radius_media;
            border-bottom-right-radius: @chat_radius_media;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                display: none;
            }

            .chat-media-item {
                cursor: pointer;
                border-radius: @chat_radius_media_image;
                border: 2px solid transparent;
                position: relative;
                width: 100%;
                height: 132px;
                margin-bottom: 10px;
                background-color: @color_chat_to_hover;

                display: flex;
                flex-direction: column;
                justify-content: center;

                &.select {
                    border: 2px solid @color_999999;
                }

                .chat-media-img {
                    width: 100%;
                    max-height: 132px;
                    background-color: #FFFFFF;
                }

                .chat-media-img-delete {
                    top: 0;
                    left: 0;
                    width: 20px;
                    z-index: 100;
                    position: absolute;
                }

            }


        }
    }
}

.chat-wrapper {
    position: relative;
    word-break: break-all;

    .chat-to {
        width: 100%;
        float: left;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .chat-img {
            margin-top: 10px;
            max-width: 300px;
            border-radius: 10px;
        }

        .chat-text {
            max-width: 90%;
            padding: 4px 10px;
            border-radius: 5px 20px 20px 20px;
            background-color: @color_chat_to;
            color: @color_chat_to_text;

            &:hover {
                background-color: @color_chat_to_hover;
            }

            pre {
                white-space: break-spaces;
            }
        }

        .info-time {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            margin: 10px 0;

            img {
                width: 36px;
                height: 36px;
                border-radius: 50%;
            }

            .name {
                color: @color_chat_name;
                margin: 0 5px;
            }

            .time {
                color: @color_chat_time;
                margin: 0 5px;
            }
        }

        .flash-cursor {
            width: 20px;
            height: 30px;
            display: inline-block;
            background: @color_333333;
            opacity: 1;
            animation: glow 800ms ease-out infinite alternate;
        }
    }

    .chat-me {
        width: 100%;
        float: right;
        margin-bottom: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .chat-img {
            margin-top: 10px;
            max-width: 300px;
            border-radius: 10px;
        }

        .chat-text {
            float: right;
            max-width: 90%;
            padding: 4px 10px;
            border-radius: 20px 5px 20px 20px;
            background-color: @color_chat_me;
            color: @color_chat_me_text;

            &:hover {
                background-color: @color_chat_me_hover;
            }

            pre {
                white-space: break-spaces;
            }
        }

        .info-time {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            margin: 10px 0;

            img {
                width: 36px;
                height: 36px;
                border-radius: 50%;
            }

            .name {
                color: @color_chat_name;
                margin: 0 5px;
            }

            .time {
                color: @color_chat_time;
                margin: 0 5px;
            }
        }
    }
}

@keyframes glow {
    0% {
        opacity: 1;
    }

    25% {
        opacity: .5;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}
</style>