<template>
    <el-upload
        :class="className"
        :drag="false"
        :action="action || getDefaultUploadUrl()"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :on-success="onSuccess"
        v-bind="$attrs">
        <slot></slot>
    </el-upload>
</template>

<script>

export default {
    name: 'upload',
    props: {
        action: {
            type: String,
            default: '',
        },
        className: {
            type: String,
            default: 'avatar-uploader',
        },
    },
    methods: {
        getDefaultUploadUrl() {
            return C.Request.getBaseUrl() + 'api/chatGPT/imageId/upload'
        },
        beforeUpload(file) {
            const limitMB = 10
            const exceedSize = file.size / 1024 / 1024 > limitMB;
            if (exceedSize) {
                this.$message.error(`上传头像图片大小不能超过 ${limitMB}MB!`);
                return false;
            }
            return true;
        },
        onSuccess(response) {
            this.$emit('onSuccess', response)
        },
    },
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.upload-file >>> .el-upload-dragger {
    width: auto;
    height: auto;
    border: none;
}
</style>
