import Vue from 'vue'
import Router from 'vue-router'

import ChatPc from '../views/chatgpt-pc/ChatPc.vue'
import Chat from '../views/chatgpt-pc/Chat.vue'
import ChatMobile from '../views/chatgpt-mobile/ChatMobile.vue'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: '/',
            component: () => import('../views/Home.vue'),
        },
        {
            path: '/navigate',
            name: '/navigate',
            component: () => import('../views/Navigate.vue'),
        },
        {
            path: '/coupon',
            name: '/coupon',
            component: () => import('../views/Coupon.vue'),
        },
        {
            path: '/software',
            name: '/software',
            component: () => import('../views/Software.vue'),
        },
        {
            path: '/chatgpt',
            redirect: '/chatgpt',
            component: ChatPc,
            children: [
                {
                    path: '/chat',
                    name: '/chat',
                    component: Chat,
                },
            ],
        },
        {
            path: '/chatmobile',
            name: '/chatmobile',
            component: ChatMobile,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
})
router.beforeEach((to, from, next) => {
    next()
})

export default router
